import React, { useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";

const UserPersonalPage = () => {
  const { user } = useAuth(); // On récupère les informations de l'utilisateur
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Les nouveaux mots de passe ne correspondent pas.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL_BACK}/api/users/update-password`,
        {
          currentPassword,
          newPassword,
        },
        { headers: { "x-auth-token": token } }
      );

      if (response.data.success) {
        setMessage("Mot de passe modifié avec succès !");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setMessage("Échec de la modification du mot de passe.");
      }
    } catch (error) {
      console.error(error);
      setMessage("Une erreur s'est produite.");
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-4xl bg-white p-10 rounded-lg">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-900">
          Mes Informations Personnelles
        </h1>
        {user ? (
          <>
            {/* Informations utilisateur */}
            <div className="mb-10 grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-lg text-blue-900 font-medium">
                  Nom d'utilisateur :
                </label>
                <p className="text-xl text-gray-800 mt-2">{user.username}</p>
              </div>
              <div>
                <label className="block text-lg text-blue-900 font-medium">
                  Email :
                </label>
                <p className="text-xl text-gray-800 mt-2">{user.email}</p>
              </div>
            </div>

            {/* Formulaire de modification du mot de passe */}
            <h2 className="text-3xl font-bold mb-6 text-blue-700 text-center">
              Modifier le Mot de Passe
            </h2>
            <form onSubmit={handlePasswordChange}>
              <div className="mb-6">
                <label className="block text-lg text-blue-900 font-medium">
                  Mot de passe actuel
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  placeholder="Entrez votre mot de passe actuel"
                />
              </div>
              <div className="mb-6">
                <label className="block text-lg text-blue-900 font-medium">
                  Nouveau mot de passe
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  placeholder="Entrez un nouveau mot de passe"
                />
              </div>
              <div className="mb-6">
                <label className="block text-lg text-blue-900 font-medium">
                  Confirmer le nouveau mot de passe
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  placeholder="Confirmez votre nouveau mot de passe"
                />
              </div>
              {message && (
                <p className="text-red-500 text-center font-semibold mb-4">
                  {message}
                </p>
              )}
              <button
                type="submit"
                className="w-full py-3 text-lg bg-yellow-500 text-white font-semibold rounded-lg hover:bg-yellow-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              >
                Modifier le mot de passe
              </button>
            </form>
          </>
        ) : (
          <p>Chargement des informations utilisateur...</p>
        )}
      </div>
    </div>
  );
};

export default UserPersonalPage;
