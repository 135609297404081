// src/components/CartPage.jsx
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useCart } from "../Context/CartContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { removeFromCart, updateCartQuantity } from "../service/cartService";
import PaypalPayment from "./PayPalPayment";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import paniervide from "../assets/videpanier.jpg";
import Footer from "./Footer";
import debounce from "./utils/debounce";

const CartPage = () => {
  const { state, dispatch, loadCart } = useCart();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState({});
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    const loadData = async () => {
      await loadCart();
      setLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    const updatedQuantities = {};
    state.products.forEach((item) => {
      const product = item.productId || item;
      updatedQuantities[product._id] = item.quantity;
    });
    setQuantities(updatedQuantities);
  }, [state.products]);

  const debouncedQuantityChange = useRef(
    debounce(async (productId, quantity) => {
      try {
        setLoadingProducts((prevLoading) => ({
          ...prevLoading,
          [productId]: true,
        }));
        await updateCartQuantity(productId, quantity);
        await loadCart();
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la quantité :", error);
      } finally {
        setLoadingProducts((prevLoading) => ({
          ...prevLoading,
          [productId]: false,
        }));
      }
    }, 500)
  ).current;

  const handleQuantityChange = (productId, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
    debouncedQuantityChange(productId, quantity);
  };

  const handleRemoveFromCart = useCallback(
    async (productId) => {
      try {
        dispatch({
          type: "REMOVE_FROM_CART_START",
          payload: { _id: productId },
        });
        await removeFromCart(productId);
        dispatch({
          type: "REMOVE_FROM_CART_SUCCESS",
          payload: { _id: productId },
        });

        await loadCart();
      } catch (error) {
        console.error(
          "Erreur lors de la suppression du produit du panier :",
          error
        );
      }
    },
    [dispatch, loadCart]
  );

  const handleLogin = () => navigate("/login");

  const totalCartPrice = state.products
    .reduce((total, item) => {
      const product = item.productId || item;
      const quantity = quantities[product._id] || item.quantity;
      return total + product.price * quantity;
    }, 0)
    .toFixed(2);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p>Chargement...</p>
      </div>
    );
  }

  return (
    <>
      <div className="container mx-auto p-6 min-h-screen">
        <h1 className="text-4xl font-bold mb-16 text-center mt-16">
          Votre Panier
        </h1>
        {state.products.length === 0 ? (
          <div className="flex flex-col items-center">
            <img
              src={paniervide}
              alt="Panier vide"
              className="w-64 h-64 mb-4"
            />
            <p className="text-gray-700">Votre panier est vide.</p>
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row gap-6">
            {/* Articles du Panier */}
            <div className="flex-1">
              {state.products.map((item, index) => {
                const { productId } = item;
                const product = productId || item;
                const quantity = quantities[product._id] || item.quantity;
                if (!productId) return null;
                return (
                  <div
                    key={product._id}
                    className="flex flex-col md:flex-row items-center bg-white p-4 mb-4 border border-gray-200 rounded-lg"
                  >
                    <div className="w-32 h-32">
                      <img
                        src={
                          product.images?.length
                            ? product.images[0]
                            : "https://via.placeholder.com/150"
                        }
                        alt={product.name || "Product Image"}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                    <div className="flex-grow md:ml-4 flex flex-col md:flex-row justify-between items-center mt-4 md:mt-0 w-full">
                      <div className="text-center md:text-left">
                        <h2 className="text-xl font-semibold mb-2">
                          {product.name}
                        </h2>
                        <div className="flex items-center">
                          <label
                            htmlFor={`quantity-${product._id}`}
                            className="mr-2"
                          >
                            Quantité:
                          </label>
                          <input
                            type="number"
                            id={`quantity-${product._id}`}
                            value={quantity}
                            min="1"
                            onChange={(e) =>
                              handleQuantityChange(
                                product._id,
                                parseInt(e.target.value)
                              )
                            }
                            className="border border-gray-300 rounded px-2 py-1 w-16 text-center"
                            disabled={loadingProducts[product._id]}
                          />
                        </div>
                        <p className="text-gray-700 text-lg font-semibold mt-2">
                          Prix unitaire: {product.price}€
                        </p>
                        <p className="text-gray-700 text-lg font-semibold">
                          Sous-total: {(product.price * quantity).toFixed(2)}€
                        </p>
                      </div>
                      <div className="mt-4 md:mt-0">
                        <button
                          className="text-red-500 hover:text-red-700 mt-2"
                          onClick={() => handleRemoveFromCart(product._id)}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Récapitulatif de la Commande */}
            <div className="lg:w-1/3 bg-white p-6 rounded-lg border border-gray-200">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                Récapitulatif de la commande
              </h2>
              <div className="mb-4">
                <h3 className="text-lg font-medium text-gray-700">
                  Sous-total :{" "}
                  <span className="font-semibold">{totalCartPrice}€</span>
                </h3>
                {/* <p className="text-sm text-gray-500">
                  Les frais de livraison seront calculés lors du paiement.
                </p> */}
              </div>
              {user ? (
                <PayPalScriptProvider
                  options={{
                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    currency: "EUR",
                  }}
                >
                  <PaypalPayment />
                </PayPalScriptProvider>
              ) : (
                <button
                  onClick={handleLogin}
                  className="w-full bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition duration-200"
                >
                  Se connecter pour finaliser votre commande
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
