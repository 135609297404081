// PaypalPayment.js
import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useCart } from "../Context/CartContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PaypalPayment = () => {
  const { state, dispatch } = useCart();
  const navigate = useNavigate();

  const createOrder = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_BACK}/api/paypal/create-order`,
        {
          items: state.products.map((product) => ({
            name: product.productId.name,
            quantity: product.quantity,
            price: product.productId.price,
          })),
          totalAmount: state.products.reduce(
            (total, product) =>
              total + product.productId.price * product.quantity,
            0
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      return response.data.orderID;
    } catch (error) {
      console.error("Failed to create PayPal order:", error);
      throw error;
    }
  };

  const onApprove = async (data) => {
    try {
      const token = localStorage.getItem("token"); // Récupérer le token d'authentification

      const orderDetails = {
        products: state.products.map((item) => ({
          productId: item.productId._id,
          quantity: item.quantity,
        })),
        totalAmount: state.products.reduce(
          (total, item) => total + item.productId.price * item.quantity,
          0
        ),
        shippingAddress: {
          fullName: "Nom du client", // Remplacer par les données réelles
          address: "Adresse du client",
          city: "Ville",
          postalCode: "Code postal",
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_BACK}/api/paypal/capture-payment?orderId=${data.orderID}`,
        orderDetails,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token, // Inclure le token dans les en-têtes
          },
        }
      );
      console.log("Payment successful:", response.data);

      // Vider le panier après le paiement réussi
      dispatch({ type: "CLEAR_CART" });
      localStorage.removeItem("cart");

      navigate("/order-success");
    } catch (error) {
      console.error("Payment capture failed:", error);
      navigate("/order-fail");
    }
  };

  const onError = (err) => {
    console.error("PayPal payment error:", err);
    navigate("/order-fail");
  };

  return (
    <div>
      <PayPalButtons
        createOrder={async (data, actions) => {
          try {
            const orderId = await createOrder();
            return orderId;
          } catch (error) {
            return actions.reject();
          }
        }}
        onApprove={onApprove}
        onError={onError}
      />
    </div>
  );
};

export default PaypalPayment;
