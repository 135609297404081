// components/useracount/OrderDetails.jsx
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getOrderById } from "../../service/orderServices";
import { useAuth } from "../../Context/AuthContext";
import { motion } from "framer-motion";

const OrderDetails = () => {
  const { id } = useParams(); // Récupérer l'ID de la commande depuis l'URL
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const data = await getOrderById(id);
        setOrder(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setLoading(false);
      }
    };
    fetchOrder();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-200 pt-16">
        <p className="text-xl text-gray-700">
          Chargement des détails de la commande...
        </p>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-200 pt-16">
        <p className="text-xl text-gray-700">Commande non trouvée.</p>
      </div>
    );
  }

  return (
    <motion.div
      className="min-h-screen bg-gray-200 py-12 px-4 "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto">
        <Link
          to="/my-orders"
          className="text-blue-500 hover:underline mb-6 inline-block pt-16"
        >
          &larr; Retour aux commandes
        </Link>
        <motion.div
          className="bg-white rounded-lg shadow-md p-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 100, damping: 20 }}
        >
          <h1 className="text-3xl font-bold mb-4 text-gray-800">
            Commande #{order._id}
          </h1>
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <span className="text-gray-600 mb-2 md:mb-0">
              <span className="font-medium">Date :</span>{" "}
              {new Date(order.createdAt).toLocaleDateString()}
            </span>
            <span
              className={`px-3 py-1 text-sm font-semibold rounded-full ${
                order.isPaid
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }`}
            >
              {order.isPaid ? "Payée" : "Non payée"}
            </span>
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Adresse de Livraison
            </h2>
            <p className="text-gray-700">
              {order.shippingAddress.fullName} <br />
              {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
              {order.shippingAddress.postalCode}
            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Détails de la Commande
            </h2>
            <ul className="space-y-4">
              {order.products.map((item) =>
                item.productId ? (
                  <motion.li
                    key={item.productId._id}
                    className="flex flex-col sm:flex-row items-center space-x-4 bg-gray-50 p-4 rounded-lg shadow-sm"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={
                        item.productId.images &&
                        item.productId.images.length > 0
                          ? item.productId.images[0]
                          : "https://via.placeholder.com/150"
                      }
                      alt={item.productId.name}
                      className="w-24 h-24 object-cover rounded-lg mb-4 sm:mb-0"
                    />

                    <div className="text-center sm:text-left">
                      <h3 className="text-lg font-medium text-gray-800">
                        {item.productId.name}
                      </h3>
                      <p className="text-gray-600">
                        Quantité : {item.quantity}
                      </p>
                      <p className="text-gray-600">
                        Prix unitaire : {item.productId.price}€
                      </p>
                    </div>
                  </motion.li>
                ) : (
                  <motion.li
                    key={item._id || `product-null-${Math.random()}`} // Utiliser une clé unique
                    className="flex flex-col sm:flex-row items-center space-x-4 bg-gray-50 p-4 rounded-lg shadow-sm"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="w-24 h-24 flex items-center justify-center bg-gray-300 rounded-lg mb-4 sm:mb-0">
                      <span className="text-gray-700">
                        Image non disponible
                      </span>
                    </div>
                    <div className="text-center sm:text-left">
                      <h3 className="text-lg font-medium text-gray-800">
                        Produit non disponible
                      </h3>
                      <p className="text-gray-600">
                        Quantité : {item.quantity}
                      </p>
                      <p className="text-gray-600">
                        Prix unitaire : {item.price ? `${item.price}€` : "N/A"}
                      </p>
                    </div>
                  </motion.li>
                )
              )}
            </ul>
          </div>
          <div className="flex justify-end">
            <span className="text-xl font-semibold text-gray-800">
              Montant Total : {order.totalAmount}€
            </span>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default OrderDetails;
