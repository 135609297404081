import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllProducts } from "../service/productsService"; // Ensure this path is correct
import Footer from "./Footer";
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";

const AmuseBouche = () => {
  const [amuseBouches, setAmuseBouches] = useState([]);

  useEffect(() => {
    const fetchAmuseBouches = async () => {
      try {
        const products = await getAllProducts();
        const amuseBoucheProducts = products.filter(
          (product) => product.category === "amusebouche"
        );
        setAmuseBouches(amuseBoucheProducts);
      } catch (error) {
        console.error("Error fetching the amuse bouches:", error);
      }
    };

    fetchAmuseBouches();
  }, []);

  return (
    <>
      <div className="bg-gray-200 min-h-screen flex flex-col pt-16">
        <div className="container mx-auto p-4 flex-grow">
          <h1 className="text-4xl font-bold my-8 text-center">
            Nos Amuse Bouches
          </h1>
          {amuseBouches.length === 0 ? (
            <p className="text-gray-700 text-center">
              Aucun produit trouvé dans la catégorie amuse bouches.
            </p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-20">
              {amuseBouches.map((amuseBouche) => (
                <div
                  key={amuseBouche._id}
                  className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <img
                    src={
                      amuseBouche.images && amuseBouche.images.length > 0
                        ? amuseBouche.images[0] // URL Cloudinary directement
                        : "https://via.placeholder.com/150"
                    }
                    alt={amuseBouche.name}
                    className="w-full h-48 object-cover mb-4 rounded-lg"
                  />
                  <h2 className="text-xl font-semibold mb-2 text-center">
                    {capitalizeFirstLetter(amuseBouche.name)}
                  </h2>
                  <p className="text-gray-700 text-center mb-4">
                    {amuseBouche.price}€
                  </p>
                  <div className="text-center">
                    <Link to={`/amuse-bouche/${amuseBouche._id}`}>
                      <button className="bg-yellow-500 hover:bg-yellow-600 text-white px-6 py-2 rounded-md transition-colors duration-300">
                        Voir les détails
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AmuseBouche;
