import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useCart } from "./CartContext";
import { addToCart, mergeCarts } from "../service/cartService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { state: cartState, dispatch: cartDispatch, loadCart } = useCart();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL_BACK}/api/users/me`, {
          headers: { "x-auth-token": token },
        })
        .then((response) => {
          setUser(response.data);
          loadCart();
        })
        .catch(() => {
          localStorage.removeItem("token");
          setUser(null);
        });
    }
  }, []); // Empty dependency array ensures this runs only once

  // const mergeCarts = async (sessionIdCart, userId) => {
  //   for (const item of sessionIdCart) {
  //     await addToCart(userId, item.productId, item.quantity);
  //   }
  // };

  const login = async (formData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_BACK}/api/users/login`,
      formData
    );
    localStorage.setItem("token", response.data.token);
    setUser(response.data.user);

    // Récupérer le panier de session si présent
    const sessionCart = JSON.parse(localStorage.getItem("cart")) || [];

    // Fusionner le panier de session avec celui de l'utilisateur
    // Appel sans userId
    await mergeCarts(sessionCart);

    // Effacer le panier de session après la fusion
    cartDispatch({ type: "CLEAR_CART" });
    localStorage.removeItem("cart");

    // Recharger le panier mis à jour
    loadCart();
  };

  const register = async (formData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_BACK}/api/users/register`,
      formData
    );
    return response.data;
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    cartDispatch({ type: "CLEAR_CART" });
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthContext, AuthProvider, useAuth };
