import React, { createContext, useReducer, useContext, useEffect } from "react";
import { getCart } from "../service/cartService";
import Cookies from "js-cookie";

const CartContext = createContext();

// Réduire les actions du panier
const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      return { ...state, products: action.payload };
    case "ADD_TO_CART":
      return {
        ...state,
        products: state.products.concat(action.payload),
      };
    case "UPDATE_CART":
      return {
        ...state,
        products: state.products.map((product) =>
          product._id === action.payload._id
            ? { ...product, quantity: action.payload.quantity }
            : product
        ),
      };
    case "REMOVE_FROM_CART_SUCCESS":
      return {
        ...state,
        products: state.products.filter(
          (product) => product._id !== action.payload._id
        ),
      };
    case "CLEAR_CART":
      return { ...state, products: [] };
    default:
      return state;
  }
};

// Provider du contexte panier
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, { products: [] });

  const loadCart = async () => {
    try {
      const cartData = await getCart();
      dispatch({ type: "SET_CART", payload: cartData.products || [] });
    } catch (error) {
      console.error("Erreur lors du chargement du panier :", error);
      dispatch({ type: "SET_CART", payload: [] });
    }
  };

  useEffect(() => {
    loadCart(); // Charger le panier au montage du composant
  }, []);

  return (
    <CartContext.Provider value={{ state, dispatch, loadCart }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte du panier
export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart doit être utilisé dans un CartProvider");
  }
  return context;
};
