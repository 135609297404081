// src/components/CartDrawer.jsx
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useCart } from "../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { updateCartQuantity, removeFromCart } from "../service/cartService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";
import debounce from "./utils/debounce"; // Import de la fonction debounce

const CartDrawer = ({ isOpen, onClose }) => {
  const { state, dispatch, loadCart } = useCart();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loadingProducts, setLoadingProducts] = useState({});
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    const updatedQuantities = {};
    state.products.forEach((item) => {
      const product = item.productId || item;
      updatedQuantities[product._id] = item.quantity;
    });
    setQuantities(updatedQuantities);
  }, [state.products]);

  const debouncedQuantityChange = useRef(
    debounce(async (productId, quantity) => {
      try {
        await updateCartQuantity(productId, quantity);
        await loadCart();
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la quantité :", error);
      } finally {
        setLoadingProducts((prevLoading) => ({
          ...prevLoading,
          [productId]: false,
        }));
      }
    }, 500)
  ).current;

  const handleQuantityChange = (productId, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
    setLoadingProducts((prevLoading) => ({
      ...prevLoading,
      [productId]: true,
    }));
    debouncedQuantityChange(productId, quantity);
  };

  const handleRemove = useCallback(
    async (productId) => {
      try {
        dispatch({
          type: "REMOVE_FROM_CART_START",
          payload: { _id: productId },
        });
        await removeFromCart(productId);
        dispatch({
          type: "REMOVE_FROM_CART_SUCCESS",
          payload: { _id: productId },
        });

        await loadCart();
      } catch (error) {
        dispatch({
          type: "REMOVE_FROM_CART_ERROR",
          payload: { _id: productId, error },
        });
        console.error(
          "Erreur lors de la suppression du produit du panier :",
          error
        );
      }
    },
    [dispatch, loadCart]
  );

  const handleViewCart = () => {
    onClose();
    navigate("/cart");
  };

  const totalCartPrice = state.products.reduce((total, item) => {
    const product = item.productId || item;
    const quantity = quantities[product._id] || item.quantity;
    return total + product.price * quantity;
  }, 0);

  return (
    <div
      className={`fixed inset-0 z-50 ${isOpen ? "block" : "hidden"}`}
      style={{ zIndex: 10000 }}
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="fixed right-0 top-0 h-full w-full sm:w-1/3 bg-white shadow-lg flex flex-col">
        <div className="p-4 flex justify-between items-center border-b">
          <h2 className="text-2xl font-bold">Votre Panier</h2>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-gray-700 cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          {state.products.length === 0 ? (
            <p className="text-gray-700">Votre panier est vide</p>
          ) : (
            <ul>
              {state.products.map((item) => {
                const product = item.productId || item;
                const quantity = quantities[product._id] || item.quantity;
                const totalItemPrice = product.price * quantity;

                return (
                  <li
                    key={product._id}
                    className="flex justify-between items-center my-4 border-b pb-4"
                  >
                    <div className="flex items-center">
                      <img
                        src={
                          product.images && product.images.length > 0
                            ? product.images[0]
                            : "https://via.placeholder.com/150"
                        }
                        alt={product.name || "Product"}
                        className="w-16 h-16 object-cover rounded mr-4"
                      />
                      <div>
                        <p className="text-lg font-semibold">
                          {capitalizeFirstLetter(product.name) ||
                            "Nom indisponible"}
                        </p>
                        <p className="text-gray-600">
                          Prix total: {totalItemPrice.toFixed(2)}€
                        </p>
                        <div className="mt-2">
                          <label
                            htmlFor={`quantity-${product._id}`}
                            className="mr-2"
                          >
                            Quantité:
                          </label>
                          <input
                            type="number"
                            id={`quantity-${product._id}`}
                            value={quantity}
                            min="1"
                            onChange={(e) =>
                              handleQuantityChange(
                                product._id,
                                parseInt(e.target.value)
                              )
                            }
                            className="border border-gray-300 rounded px-2 py-1 w-16 text-center"
                            disabled={loadingProducts[product._id]}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemove(product._id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="p-4 border-t">
          <div className="text-right mb-4">
            <h3 className="text-xl font-semibold">
              Total du Panier: {totalCartPrice.toFixed(2)}€
            </h3>
          </div>
          <button
            onClick={handleViewCart}
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300"
          >
            Voir Panier
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;
