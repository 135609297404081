// src/Components/useracount/UserOrders.jsx
import React, { useEffect, useState } from "react";
import { getUserOrders } from "../../service/orderServices";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await getUserOrders();
        setOrders(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-200 pt-16">
        <p className="text-xl text-gray-700">Chargement des commandes...</p>
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-200 pt-16">
        <p className="text-xl text-gray-700">Aucune commande trouvée.</p>
      </div>
    );
  }

  // Définir les variantes d'animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Anime les enfants avec un délai entre eux
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
    hover: {
      scale: 1.02,
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div
      className="min-h-screen bg-gray-200 py-12 px-4 "
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="container mx-auto pt-16">
        <h1 className="text-4xl font-bold mb-14 text-center">Mes Commandes</h1>
        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {orders.map((order) => (
            <motion.div
              key={order._id}
              className="bg-white rounded-lg shadow-md p-6 flex flex-col"
              variants={cardVariants}
              whileHover="hover"
            >
              <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-800">
                  Commande #{order._id}
                </h2>
                <span
                  className={`px-3 py-1 text-sm font-semibold rounded-full ${
                    order.isPaid
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  }`}
                >
                  {order.isPaid ? "Payée" : "Non payée"}
                </span>
              </div>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Date :</span>{" "}
                {new Date(order.createdAt).toLocaleDateString()}
              </p>
              <p className="text-gray-600 mb-4">
                <span className="font-medium">Montant total :</span>{" "}
                {order.totalAmount}€
              </p>
              <div className="flex-1">
                <h3 className="text-xl font-semibold mb-2">Produits :</h3>
                <ul className="list-disc list-inside space-y-2">
                  {order.products.map((item) =>
                    item.productId ? (
                      <li key={item.productId._id} className="text-gray-700">
                        <span className="font-medium">
                          {item.productId.name}
                        </span>{" "}
                        - Quantité : {item.quantity} - Prix unitaire :{" "}
                        {item.productId.price}€
                      </li>
                    ) : (
                      <li key={item._id} className="text-gray-700">
                        <span className="font-medium">
                          Produit non disponible
                        </span>
                        {" - Quantité : "}
                        {item.quantity} - {"Prix unitaire : "}
                        {item.price}€
                      </li>
                    )
                  )}
                </ul>
              </div>
              {/* Bouton de détails */}
              <Link
                to={`/my-orders/${order._id}`}
                className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 text-center"
              >
                Voir les détails
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default UserOrders;
