// services/orderService.js
import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_URL_BACK}/api/orders`;

export const getUserOrders = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/my-orders`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user orders:", error);
    throw error;
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await axios.get(`${API_URL}/my-orders/${orderId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching order details:", error);
    throw error;
  }
};
