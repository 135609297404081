// components/Home.jsx
import React from "react";
import { Link as ScrollLink, Element } from "react-scroll";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; // Importer Framer Motion
import bgImage from "../assets/another_bg_epices_royales.jpg"; // Importer l'image de fond
import ownerImage from "../assets/p1.png"; // Importer l'image du propriétaire
import companyImage from "../assets/p2.png"; // Importer l'image de l'entreprise
import Footer from "./Footer";

// Définir les variantes d'animation
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3, // Anime les enfants avec un délai entre eux
    },
  },
};

const textVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
    },
  },
};

const imageVariants = {
  hidden: { opacity: 0, x: 100, scale: 0.8 },
  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
      duration: 0.5,
    },
  },
  hover: {
    scale: 1.05,
    rotate: 2,
    transition: {
      duration: 0.3,
      type: "spring",
      stiffness: 300,
      damping: 10,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.05,
    boxShadow: "0px 0px 15px rgba(255, 255, 255, 0.6)",
    transition: {
      duration: 0.3,
      yoyo: Infinity, // Fait osciller l'animation
    },
  },
};

const Home = () => {
  return (
    <>
      <div className="font-sans">
        {/* Section principale avec l'image de fond */}
        <motion.div
          className="relative bg-cover bg-center min-h-screen flex items-center justify-center"
          style={{ backgroundImage: `url(${bgImage})` }} // Utiliser l'image importée
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          {/* Superposition pour améliorer la lisibilité du texte */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <motion.div
            className="relative z-10 container mx-auto p-4 text-center text-white"
            variants={containerVariants}
          >
            <motion.h1
              className="font-montserrat text-4xl md:text-5xl lg:text-6xl font-bold mb-6"
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              Bienvenue chez Epices Royales!
            </motion.h1>
            <motion.p
              className="mb-8 text-base md:text-lg lg:text-xl"
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              Nous offrons une large sélection d'épices exotiques et de haute
              qualité pour satisfaire toutes vos envies culinaires.
            </motion.p>
            <motion.div
              className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                <ScrollLink
                  to="companySection"
                  smooth={true}
                  duration={500}
                  className="cursor-pointer bg-white hover:bg-black text-black hover:text-white font-semibold py-3 px-6 sm:px-8 rounded-full shadow-lg transition duration-300"
                  whileHover="hover"
                  variants={buttonVariants}
                >
                  En savoir plus
                </ScrollLink>
              </motion.div>
              <motion.div
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                <Link
                  className="bg-yellow-500 hover:bg-yellow-600 text-black font-semibold py-3 px-6 sm:px-8 rounded-full shadow-lg transition duration-300"
                  to="/epices"
                  whileHover="hover"
                  variants={buttonVariants}
                >
                  Découvrir nos produits
                </Link>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>

        {/* Section de présentation de l'entreprise */}
        <Element name="companySection" className="bg-white py-16 px-4">
          <motion.div
            className="container mx-auto flex flex-col md:flex-row items-center"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div
              className="md:w-1/2 p-4"
              variants={imageVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
            >
              <img
                src={companyImage}
                alt="Épices de qualité"
                className="rounded-lg shadow-lg w-full"
              />
            </motion.div>
            <motion.div
              className="md:w-1/2 p-4"
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              <h2 className="font-montserrat text-3xl md:text-4xl font-bold mb-4 text-gray-800">
                Présentation de l'entreprise
              </h2>
              <p className="text-lg md:text-xl text-gray-700">
                Notre entreprise, Épices Royale, est dédiée à apporter les
                épices les plus fines et les plus exotiques de partout dans le
                monde. Chaque épice est soigneusement sélectionnée pour sa
                qualité, sa saveur et son arôme. Nous croyons que les épices
                sont l'âme de la cuisine, et nous nous efforçons de fournir les
                meilleurs produits pour enrichir vos plats et éveiller vos sens.
              </p>
            </motion.div>
          </motion.div>
        </Element>

        {/* Section de présentation du propriétaire */}
        <motion.div
          className="bg-gray-100 py-16 px-4"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <motion.div
            className="container mx-auto flex flex-col md:flex-row items-center"
            variants={containerVariants}
          >
            <motion.div
              className="md:w-1/2 p-4 order-2 md:order-1"
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              <h2 className="font-montserrat text-3xl md:text-4xl font-bold mb-4 text-gray-800">
                Présentation du propriétaire
              </h2>
              <p className="text-lg md:text-xl text-gray-700">
                Notre propriétaire, de-SOUZA Edith, est un passionné de cuisine
                et un expert en épices avec plus de 20 ans d'expérience dans le
                domaine. Il sélectionne personnellement chaque épice pour
                garantir la meilleure qualité à nos clients. Sa passion et son
                expertise se reflètent dans chaque produit que nous offrons.
              </p>
            </motion.div>
            <motion.div
              className="md:w-1/2 p-4 order-1 md:order-2"
              variants={imageVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
            >
              <img
                src={ownerImage}
                alt="Propriétaire"
                className="rounded-lg shadow-lg w-full"
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
