// src/components/AdminProducts.jsx
import React, { useState, useEffect } from "react";
import {
  createProduct,
  getAllProducts,
  deleteProduct,
  updateProduct,
} from "../service/productsService";
import {
  PencilAltIcon,
  TrashIcon,
  PlusCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // États pour le formulaire d'ajout/modification
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);

  // Charger les produits au chargement du composant
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await getAllProducts();
      setProducts(res);
    } catch (err) {
      console.error("Erreur lors du chargement des produits :", err);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages([...images, ...files]);

    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews([...imagePreviews, ...newPreviews]);
  };

  const handleDeleteImage = (imageIndex) => {
    setImages(images.filter((_, index) => index !== imageIndex));
    setImagePreviews(imagePreviews.filter((_, index) => index !== imageIndex));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("category", category);
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }

    try {
      if (isEditing) {
        // Mettre à jour le produit existant
        await updateProduct(editingProductId, formData);
      } else {
        // Ajouter un nouveau produit
        await createProduct(formData);
      }

      // Réinitialiser le formulaire
      resetForm();
      // Recharger les produits
      fetchProducts();
    } catch (err) {
      console.error("Erreur lors de l'ajout/mise à jour du produit :", err);
      alert("Échec de l'opération");
    }
  };

  const resetForm = () => {
    setName("");
    setPrice("");
    setDescription("");
    setCategory("");
    setImages([]);
    setImagePreviews([]);
    setIsEditing(false);
    setEditingProductId(null);
    setShowModal(false);
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?")) {
      try {
        await deleteProduct(productId);
        alert("Produit supprimé avec succès !");
        // Recharger les produits
        fetchProducts();
      } catch (err) {
        console.error("Erreur lors de la suppression du produit :", err);
        alert("Échec de la suppression du produit");
      }
    }
  };

  const handleEditProduct = (product) => {
    setIsEditing(true);
    setEditingProductId(product._id);
    setName(product.name);
    setPrice(product.price);
    setDescription(product.description);
    setCategory(product.category);
    setImages([]); // Réinitialiser les images à uploader
    setImagePreviews(product.images); // Utiliser les URLs existantes pour les aperçus
    setShowModal(true);
  };

  return (
    <div className="mt-16">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-800">
          Gestion des produits
        </h1>
        <button
          onClick={() => setShowModal(true)}
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
        >
          <PlusCircleIcon className="w-5 h-5 mr-2" />
          Ajouter un produit
        </button>
      </div>

      {/* Liste des produits */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow rounded-lg">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-gray-600 font-medium">
                Nom
              </th>
              <th className="px-6 py-3 text-left text-gray-600 font-medium">
                Prix
              </th>
              <th className="px-6 py-3 text-left text-gray-600 font-medium">
                Catégorie
              </th>
              <th className="px-6 py-3 text-center text-gray-600 font-medium">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {products && products.length > 0 ? (
              products.map((product, index) => (
                <tr
                  key={product._id}
                  className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.price} €
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {product.category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <button
                      onClick={() => handleEditProduct(product)}
                      className="text-blue-600 hover:text-blue-800 mr-4"
                    >
                      <PencilAltIcon className="w-5 h-5 inline" />
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product._id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <TrashIcon className="w-5 h-5 inline" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-6 py-4 text-center" colSpan="4">
                  Aucun produit trouvé.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal pour ajouter/modifier un produit */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
          <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl mx-auto relative">
            <button
              onClick={resetForm}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <XIcon className="w-6 h-6" />
            </button>
            <h2 className="text-2xl font-bold mb-4">
              {isEditing ? "Modifier le produit" : "Ajouter un nouveau produit"}
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-2">
                    Nom du produit
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-2">
                    Prix
                  </label>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Description
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                  required
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-2">
                    Catégorie
                  </label>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  >
                    <option value="">Sélectionner une catégorie</option>
                    <option value="epices">Epices</option>
                    <option value="amusebouche">Amuse Bouche</option>
                    <option value="mugs">Mugs</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-2">
                    Images
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    className="w-full"
                    accept="image/*"
                  />
                </div>
              </div>

              {/* Affichage des aperçus des images */}
              {imagePreviews.length > 0 && (
                <div className="mb-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {imagePreviews.map((preview, index) => (
                    <div key={index} className="relative">
                      <img
                        src={preview}
                        alt={`Preview ${index + 1}`}
                        className="object-cover w-full h-32 rounded border"
                      />
                      <button
                        type="button"
                        onClick={() => handleDeleteImage(index)}
                        className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                      >
                        <XIcon className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-end mt-6">
                <button
                  type="button"
                  onClick={resetForm}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600 transition duration-200"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
                >
                  {isEditing ? "Mettre à jour" : "Ajouter le produit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminProducts;
