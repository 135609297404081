// src/service/productsService.js
import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_URL_BACK}/api/products`;
console.log("Backend API URL:", process.env.REACT_APP_BASE_URL_BACK);

// Obtenir tous les produits
export const getAllProducts = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data; // Retourne le tableau de produits directement
  } catch (error) {
    console.error("Erreur lors de la récupération des produits :", error);
    throw error;
  }
};

// Créer un nouveau produit
export const createProduct = async (productData) => {
  try {
    const response = await axios.post(`${API_URL}/add`, productData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du produit :", error);
    throw error;
  }
};

// Mettre à jour un produit existant
export const updateProduct = async (productId, updatedData) => {
  try {
    const response = await axios.put(`${API_URL}/${productId}`, updatedData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du produit :", error);
    throw error;
  }
};

// Supprimer un produit
export const deleteProduct = async (productId) => {
  try {
    const response = await axios.delete(`${API_URL}/${productId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression du produit :", error);
    throw error;
  }
};

// Supprimer une image spécifique d'un produit
export const deleteImageProduct = async (productId, imagePath) => {
  try {
    const response = await axios.delete(`${API_URL}/delete-image`, {
      data: { productId, imagePath },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'image :", error);
    throw error;
  }
};
