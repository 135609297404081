import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllProducts } from "../service/productsService"; // Assurez-vous que le chemin est correct
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";
import Footer from "./Footer";

const Epices = () => {
  const [epices, setEpices] = useState([]);

  useEffect(() => {
    const fetchEpices = async () => {
      try {
        const products = await getAllProducts();
        const epicesProducts = products.filter(
          (product) => product.category === "epices"
        );
        setEpices(epicesProducts);
      } catch (error) {
        console.error("Error fetching the epices:", error);
      }
    };

    fetchEpices();
  }, []);

  return (
    <>
      <div className="bg-gray-200 min-h-screen flex flex-col pt-16">
        <div className="container mx-auto p-4 flex-grow">
          <h1 className="text-4xl font-bold my-8 text-center">Nos Épices</h1>
          {epices.length === 0 ? (
            <p className="text-gray-700 text-center">
              Aucun produit trouvé dans la catégorie épices.
            </p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-20">
              {epices.map((epice) => (
                <div
                  key={epice._id}
                  className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <img
                    src={
                      epice.images.length > 0
                        ? epice.images[0] // Utiliser directement l'URL de Cloudinary
                        : "https://via.placeholder.com/150"
                    }
                    alt={epice.name}
                    className="w-full h-48 object-cover mb-4 rounded-lg"
                  />

                  <h2 className="text-xl font-semibold mb-2 text-center">
                    {capitalizeFirstLetter(epice.name)}
                  </h2>
                  <p className="text-gray-700 text-center mb-4">
                    {epice.price}€
                  </p>
                  <div className="text-center">
                    <Link to={`/epices/${epice._id}`}>
                      <button className="bg-yellow-500 hover:bg-yellow-600 text-white px-6 py-2 rounded-md transition-colors duration-300">
                        Voir les détails
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Epices;
