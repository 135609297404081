// src/components/AdminMenu.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  MenuIcon,
  XIcon,
  UsersIcon,
  ShoppingCartIcon,
  CubeIcon,
} from "@heroicons/react/outline"; // Utilisation des icônes Heroicons

const AdminMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Fonction pour gérer l'ouverture et la fermeture du menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex">
      {/* Bouton pour ouvrir/fermer le menu sur petits écrans */}
      <button
        className="text-white p-2 bg-gray-800 lg:hidden focus:outline-none"
        onClick={toggleMenu}
      >
        {isMenuOpen ? (
          <XIcon className="w-6 h-6" />
        ) : (
          <MenuIcon className="w-6 h-6" />
        )}
      </button>

      {/* Menu Admin */}
      <div
        className={`bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition duration-200 ease-in-out lg:relative lg:translate-x-0`}
      >
        <h2 className="text-2xl font-bold text-center mb-8">Admin Panel</h2>
        <nav>
          <Link
            to="/admin/users"
            className="flex items-center px-4 py-2 text-gray-200 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            onClick={toggleMenu}
          >
            <UsersIcon className="w-5 h-5 mr-3" />
            Gérer les utilisateurs
          </Link>
          <Link
            to="/admin/orders"
            className="flex items-center px-4 py-2 text-gray-200 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            onClick={toggleMenu}
          >
            <ShoppingCartIcon className="w-5 h-5 mr-3" />
            Gérer les commandes
          </Link>
          <Link
            to="/admin/products"
            className="flex items-center px-4 py-2 text-gray-200 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            onClick={toggleMenu}
          >
            <CubeIcon className="w-5 h-5 mr-3" />
            Gérer les produits
          </Link>
        </nav>
      </div>

      {/* Overlay pour fermer le menu en cliquant à l'extérieur */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 lg:hidden"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
};

export default AdminMenu;
