import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useCart } from "../Context/CartContext";
import { useNavigate } from "react-router-dom";

import PaypalPayment from "./PayPalPayment";

const Checkout = () => {
  const { state } = useCart();
  const navigate = useNavigate();

  const validateProducts = () => {
    return state.products.every(
      (product) =>
        product.productId.price !== undefined &&
        product.productId.price !== null &&
        !isNaN(product.productId.price) &&
        product.quantity !== undefined &&
        product.quantity !== null &&
        !isNaN(product.quantity)
    );
  };

  const initialoptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "capture",
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Checkout</h1>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-2/3 px-4">
          <PayPalScriptProvider options={initialoptions}>
            {/* <PaypalPayment /> */}
          </PayPalScriptProvider>
        </div>
        <div className="w-full lg:w-1/3 px-4 mt-8 lg:mt-0">
          <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
          <ul>
            {state.products.map((product) => (
              <li
                key={product.productId._id}
                className="flex justify-between items-center mb-4"
              >
                <div>
                  <p className="text-lg font-semibold">
                    {product.productId.name}
                  </p>
                  <p className="text-gray-600">Quantité: {product.quantity}</p>
                </div>
                <div>
                  <p className="text-lg font-semibold">
                    {product.productId.price}€
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <div className="border-t pt-4">
            <p className="text-lg font-semibold">
              Total:{" "}
              {state.products
                .reduce(
                  (total, product) =>
                    total + product.productId.price * product.quantity,
                  0
                )
                .toFixed(2)}{" "}
              €
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
