// src/components/AmuseBoucheDetails.jsx
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllProducts } from "../service/productsService";
import { useCart } from "../Context/CartContext";
import { addToCart } from "../service/cartService";
import Footer from "./Footer";
import { useAuth } from "../Context/AuthContext";
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";

import IconButton from "@mui/material/IconButton";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const AmuseBoucheDetails = () => {
  const { id } = useParams();
  const [amuseBouche, setAmuseBouche] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { dispatch } = useCart();
  const { user } = useAuth();
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Déclaration manquante ajoutée ici

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const products = await getAllProducts();
        const foundProduct = products.find((product) => product._id === id);
        setAmuseBouche(foundProduct);
      } catch (error) {
        console.error("Error fetching the product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = async () => {
    try {
      await addToCart(
        user ? user._id : null,
        amuseBouche._id,
        parseInt(quantity)
      );
      dispatch({
        type: "ADD_TO_CART",
        payload: { ...amuseBouche, quantity: parseInt(quantity) },
      });
      alert("Produit ajouté au panier avec succès !");
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("Échec de l'ajout du produit au panier.");
    }
  };

  // Fonctions pour naviguer entre les images
  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? amuseBouche.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === amuseBouche.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (!amuseBouche) {
    return <div>Produit non trouvé !</div>;
  }

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-200 pt-16">
        <div className="container mx-auto py-12 flex-grow flex flex-col lg:flex-row items-center justify-between">
          {/* Section des images avec navigation */}
          <div className="flex-1 flex justify-center items-center mb-12 lg:mb-0">
            <div className="relative">
              <img
                src={
                  amuseBouche.images && amuseBouche.images.length > 0
                    ? amuseBouche.images[currentImageIndex]
                    : "https://via.placeholder.com/400"
                }
                alt={`Image ${currentImageIndex + 1} de ${amuseBouche.name}`}
                className="rounded-xl object-cover max-h-[400px] shadow-lg border border-gray-200 w-full"
              />
              {amuseBouche.images.length > 1 && (
                <>
                  <IconButton
                    onClick={handlePreviousImage}
                    style={{
                      position: "absolute",
                      left: 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <IconButton
                    onClick={handleNextImage}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </>
              )}
            </div>
          </div>

          {/* Section des détails du produit */}
          <div className="flex-1 bg-white p-8 rounded-lg shadow-lg">
            <span className="flex justify-between">
              <h1 className="text-4xl font-semibold text-gray-800 mb-4">
                {capitalizeFirstLetter(amuseBouche.name)}
              </h1>
              <p className="text-3xl font-semibold text-yellow-700 mb-6">
                {amuseBouche.price}€
              </p>
            </span>

            <p className="text-lg text-gray-700 mb-4">
              {amuseBouche.description}
            </p>

            <div className="flex items-center space-x-4 mb-6">
              <label className="block text-lg font-medium text-gray-700">
                Quantité
              </label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="w-24 px-4 py-2 border border-gray-300 rounded-lg text-lg"
                min="1"
              />
            </div>

            <button
              onClick={handleAddToCart}
              className="bg-yellow-700 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-600 transition duration-200"
            >
              Ajouter au panier
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AmuseBoucheDetails;
