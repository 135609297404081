import axios from "axios";
import Cookies from "js-cookie";

const API_URL = `${process.env.REACT_APP_BASE_URL_BACK}/api/cart`;

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return token ? { "x-auth-token": token } : {};
};

export const addToCart = async (productId, quantity) => {
  try {
    await axios.post(
      `${API_URL}/add`,
      { productId, quantity },
      {
        headers: getAuthHeaders(),
        withCredentials: true,
      }
    );
  } catch (error) {
    console.error("Error adding to cart:", error);
    throw error;
  }
};

export const mergeCarts = async (sessionCart) => {
  try {
    // Récupérer le panier de l'utilisateur connecté depuis le back-end
    const { data: userCart } = await axios.get(`${API_URL}`, {
      headers: getAuthHeaders(),
      withCredentials: true,
    });

    // Créer un objet pour stocker les produits fusionnés
    const mergedProducts = {};

    // Ajouter les produits du panier de l'utilisateur connecté
    if (userCart && userCart.products) {
      for (const item of userCart.products) {
        mergedProducts[item.productId._id] = item.quantity;
      }
    }

    // Fusionner les produits du panier de session (localStorage)
    for (const sessionItem of sessionCart) {
      const productId = sessionItem.productId;
      const quantity = sessionItem.quantity;

      if (mergedProducts[productId]) {
        // Si le produit existe déjà, additionner les quantités
        mergedProducts[productId] += quantity;
      } else {
        // Sinon, ajouter le produit
        mergedProducts[productId] = quantity;
      }
    }

    // Mettre à jour le panier de l'utilisateur avec les produits fusionnés
    for (const productId in mergedProducts) {
      const quantity = mergedProducts[productId];
      await addToCart(productId, quantity);
    }
  } catch (error) {
    console.error("Error merging carts:", error);
  }
};

export const removeFromCart = async (productId) => {
  try {
    const sessionId = Cookies.get("sessionId");
    await axios.post(
      `${API_URL}/remove`,
      { productId },
      {
        headers: getAuthHeaders(),
        withCredentials: true,
        params: sessionId ? { sessionId } : {},
      }
    );
  } catch (error) {
    console.error("Error removing from cart:", error);
    throw error;
  }
};

export const getCart = async () => {
  try {
    const response = await axios.get(API_URL, {
      headers: getAuthHeaders(),
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cart:", error);
    throw error;
  }
};

export const updateCartQuantity = async (productId, quantity) => {
  try {
    const response = await axios.post(
      `${API_URL}/update-quantity`,
      { productId, quantity },
      {
        headers: getAuthHeaders(),
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating cart quantity:", error);
    throw error;
  }
};
