import React from "react";
import { useNavigate } from "react-router-dom";
import merci from "../assets/merci.jpg";
import Footer from "./Footer";

const OrderSuccess = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md">
          <img src={merci} alt="Order Success" className="mx-auto mb-6" />
          <h1 className="text-3xl font-bold text-orange-500 mb-4">
            Merci pour votre commande!
          </h1>
          <p className="text-gray-700 mb-6">
            Votre commande a été passée avec succès. Nous vous enverrons un
            email de confirmation avec les détails de votre commande.
          </p>
          <button
            onClick={handleRedirect}
            className=" bg-orange-500 text-white px-6 py-3 rounded-full shadow-lg hover:bg-orange-800 transition-colors"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderSuccess;
